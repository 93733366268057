<template>
    <section class="tags py-4">
        <p class="f-15 text-black2 mb-3 px-3">Etiquetas de descuento</p>
        <div class="d-middle px-3">
            <div class="mr-3">
                <p class="f-12 text-black2 pl-3">Etiqueta de descuento</p>
                <el-input v-model="texto" placeholder="Etiqueta de decuento" size="medium" class="h-36px w-280px" />
            </div>
            <button class="btn btn-general h-36px mt-auto w-137px" @click="addEtiqueta">
                Crear
            </button>
        </div>
        <div class="br-12 px-3 py-4 bg-white my-4 wM-521px" style="box-shadow: 1px 1px 4px #00000014;">
            <div v-for="(tag, index) in data" :key="index" class="d-middle mb-2">
                <el-input v-model="tag.texto" placeholder="Etiqueta" :disabled="idTag != tag.id" size="medium" class="h-36px w-280px input-pr mr-3" />
                <div class="col-auto px-2">
                    <el-tooltip v-if="idTag != tag.id" class="item" effect="light" content="Editar" placement="bottom">
                        <div class="btn-action border" @click="idTag=tag.id">
                            <i class="icon-pencil-outline f-17" />
                        </div>
                    </el-tooltip>
                    <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                        <div class="btn-action border" @click="updateEtiqueta(tag.texto)">
                            <i class="icon-ok-circled-outline f-17 text-success" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto px-2">
                    <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                        <div class="btn-action border" @click="$refs.modalEliminar.toggle(), idTag = tag.id">
                            <i class="icon-delete-outline f-17" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto">
                    <el-tooltip class="item" effect="light" placement="bottom">
                        <div slot="content" class="text-center" style="max-width:140px;">
                            <p class="wM-115px">Productos que tienen esta etiqueta</p>
                        </div>
                        <div class="br-20 bg-general3 d-middle-bt px-2 text-white wf-70px hf-28px">
                            <i class="icon-package-variant-closed f-18" />
                            <span class="pr-2">{{ tag.count }}</span>
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar" mensaje="¿Desea eliminar la etiqueta?" @eliminar="eliminarRegistro" />
    </section>
</template>

<script>
import Service from '~/services/configurar/admin/descuentosLista';
export default {
    data(){
        return {
            editar: -1,
            texto: '',
            idTag:null,
            data:[]
        }
    },
    mounted(){
        this.getEtiquetas();
    },
    methods: {
        async getEtiquetas(){
            try {
                const {data} = await Service.getEtiquetas();
                this.data = data.etiquetas;
            } catch(e){
                this.error_catch(e);
            }
        },
        handleEditTag(){
            this.editar = -1
        },
        async eliminarRegistro(){
            try {
                if(this.idTag == null) return
                const {data} = await Service.deleteEtiqueta({id:this.idTag});
                this.$refs.modalEliminar.toggle();
                this.notificacion('Exito','Registro eliminado correctamente','success')
                this.idTag = null
                this.getEtiquetas()
            } catch(e){
                this.idTag = null
                this.error_catch(e);
            }
        },
        async updateEtiqueta(texto){
            try {
                if(this.idTag == null) return
                const payload = {
                    id:this.idTag,
                    nombre:texto
                }
                const {data} = await Service.updateEtiqueta(payload);
                this.notificacion('Exito','Registro actualizado correctamente','success')
                this.idTag = null
                this.getEtiquetas()
            } catch(e){
                this.idTag = null
                this.error_catch(e);
            }
        },
        async addEtiqueta(){
            try {
                const payload = {
                    nombre:this.texto
                }
                const {data} = await Service.addEtiqueta(payload);
                this.notificacion('Exito','Registro agregado correctamente','success')
                this.texto = ''
                this.getEtiquetas()
            } catch(e){
                this.error_catch(e);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.wM-521px{
	max-width: 521px;
}
</style>
